<template>
  <div>
    <el-steps :active="active" finish-status="success" align-center @click="aaa">
      <el-step title="开户申请"></el-step>
      <el-step title="绑卡提交"></el-step>
      <el-step title="绑卡确认"></el-step>
      <el-step title="签约"></el-step>
    </el-steps>
  </div>
</template>

<script>
import * as types from "@/store/action-types";
import Vue from "vue";

export default {
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    this.getactive();
  },
  methods: {
    aaa(){
      console.log('11111111')
    },
    getactive() {
      this.active = 0;
      this.active = Number(Vue.ls.get(types.active));
      
    },
  },
};
</script>

<style>
</style>